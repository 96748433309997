import React, { useState, useEffect } from "react";
// import { graphql, useStaticQuery } from "gatsby";

// import { nanoid } from "nanoid";

// import Img from "gatsby-image";

import axios from "axios";

import Layout from "../components/layout";
import SEO from "../components/seo";

function Advantages() {
  const [pods, setPods] = useState([]);

  // const { allFile } = useStaticQuery(graphql`
  //   query {
  //     allFile(filter: { sourceInstanceName: { eq: "advants" } }) {
  //       edges {
  //         node {
  //           childImageSharp {
  //             fluid {
  //               ...GatsbyImageSharpFluid
  //             }
  //           }
  //           name
  //           id
  //         }
  //       }
  //     }
  //   }
  // `);
  // const { edges } = allFile;

  useEffect(() => {
    const getPods = async () => {
      const response = await axios.get(
        "https://wp-api.proff86.ru/wp-json/wp/v2/advantages"
      );
      // console.log("@@@", response.data);
      setPods(response.data);
    };
    getPods();
  }, []);

  return (
    <Layout>
      <SEO
        keywords={[`ЧОУ Профф`, `наши преимущества`, `грамоты, награды`]}
        title="Преимущества"
      />

      <section className="flex flex-col items-center ">
        <h1 className=" text-center text-2xl font-bold uppercase tracking-wider my-4 text-green-800 ">
          Наши преимущества
        </h1>
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {pods.map((pod) => (
            <div
              key={pod.id}
              className=" transform hover:scale-105 transition 
              ease-in-out duration-700 w-56 h-80"
            >
              <a
                href={pod.filename.guid}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  key={pod.id}
                  src={pod.filename.guid}
                  className="w-full h-full object-fill"
                  alt="advantage"
                />
              </a>
            </div>
          ))}
          {/* {edges.map(({ node }) => (
            <div
              key={node.id}
              className="
            transform hover:scale-105 transition 
              ease-in-out duration-700 w-56"
            >
              <a
                href={node.childImageSharp.fluid.src}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img fluid={node.childImageSharp.fluid} />
              </a>
            </div>
          ))} */}
        </div>
      </section>
    </Layout>
  );
}

export default React.memo(Advantages);
